import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Particle from "../Particle";
import FCard from "./FCard";
import { AiOutlineMail, AiOutlinePhone, AiFillInstagram } from "react-icons/ai";
import { FaFacebookMessenger } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";

function FAQs() {
    return (
        <Container fluid className="about-section">
            <Particle />
            <Container>
                <Row style={{ justifyContent: 'center' }}>
                    <Col md={7} className="home-header" style={{ justifyContent: 'center', zIndex: '1', marginBottom: '0px', marginTop: '50px' }}>
                    
                        <h1 className="projects__heading-name">
                            <strong className="main-name"> How Do Revisions Work?</strong>
                        </h1>

                    </Col>
                </Row>
                <br></br>
                <Row className="faqs__row" style={{ justifyContent: 'center' }}>
                    {/* <Col>
                        Send Us Your <strong className="FAQs__gold">Questions!</strong>
                    </Col> */}
                </Row>

                <FCard />

                <Row style={{ justifyContent: "center", padding: "10px", marginBottom: "0" }}>

                    <Link to="/contact" style={{ textDecoration: 'none', zIndex: '2', justifyContent: 'center', display: 'flex' }}>
                        <Button 
                            href="/contact" 
                            className="fork-btn-inner" 
                            style={{ 
                            width: '85%', 
                            height: '50px', 
                            margin: '0px auto 50px auto', 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: '2',
                            }}
                        >
                            <span style={{ color: "black", fontWeight: "600", fontSize: '1.2em' }}>Still Got Questions?</span>
                        </Button>
                    </Link>
                </Row>

                {/* <h1 className="project-heading">
                Our Happy <strong className="purple">Clients! </strong>
                </h1> */}

            </Container>
        </Container>
    );
}

export default FAQs;
