import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPython,
} from "react-icons/si";

import windowsImage from "./icons/windows.png";
import jsImage from "./icons/js.png";
import parsecImage from "./icons/parsec.png";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <img src={windowsImage} style={{ filter: 'invert(1)', padding: '10px'}} className="skill-icon" alt="Microsoft Windows" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={jsImage} style={{ filter: 'invert(1)', padding: '5px'}} className="skill-icon" alt="JavaScript" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={parsecImage} style={{ filter: 'invert(1)'}} className="skill-icon" alt="Parsec" />
      </Col>
    </Row>
  );
}

export default Toolstack;
