import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { ImArrowRight2, ImArrowDown2 } from "react-icons/im";

function FCard() {
  const [activeSection, setActiveSection] = useState("0"); // Set the initial active section

  const handleSectionClick = (section) => {
    if (section === activeSection) {
      setActiveSection(null); // Close the section if it's already open
    } else {
      setActiveSection(section); // Open the section if it's closed or switch to another section
    }
  };

  const isSectionActive = (section) => section === activeSection;

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <p style={{fontSize: "1.5em", textAlign: "left"}}>
          You, the client, have 2 rounds of revisions included in the total project budget. These two revision rounds are structured as follows:
        </p>
        <p className="purple" style={{fontSize: "1.5em", fontWeight: "800", textAlign: "left"}}>
          Revision Round 1:
        </p>
        <p style={{fontSize: "1.5em", textAlign: "left"}}>
          Possible feedback to everything is accepted as long as it does not differ from
          the initial idea and agreements. We value creativity and innovation, and therefore are very open to tweaks and modifications,
          but deviations from the agreed-upon concept may require further discussion and approval to maintain consistency.
        </p>
        <p className="purple" style={{fontSize: "1.5em", fontWeight: "800", textAlign: "left"}}>
          Revision Round 2:
        </p>
        <p style={{fontSize: "1.5em", textAlign: "left"}}>
          Feedback only to the changes made after Revision Round 1 is accepted. If
          something else needs to be changed outside of what was mentioned in Revision Round 1, ODA
          has the right to charge the client for the additional work. Having this policy ensures clarity and accountability in our collaborative process while
          allowing for the freedom of creativity on both ends of the deal.
        </p>
      </Card.Body>
    </Card>
  );
}

export default FCard;
