import React from "react";
import { Col, Row } from "react-bootstrap";

import photoshopImage from "./icons/photoshop.png";
import lightroomImage from "./icons/lightroom.png";
import aeImage from "./icons/ae.png";
import premiereImage from "./icons/premiere.png";
import resolveImage from "./icons/resolve.png";
import canonImage from "./icons/canon.png";
import djiImage from "./icons/dji.png";
import rodeImage from "./icons/rode.png";
import sandiskImage from "./icons/sandisk.png";
import ftfImage from "./icons/ftf.png";
import artlistImage from "./icons/artlist.png";
import descriptImage from "./icons/descript.png";
import megaImage from "./icons/mega.png";
import epsonImage from "./icons/epson.png";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <img src={photoshopImage} style={{ filter: 'invert(1)'}} className="skill-icon" alt="Adobe Photoshop" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={lightroomImage} style={{ filter: 'invert(1)'}} className="skill-icon" alt="Adobe Lightroom" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={aeImage} style={{ filter: 'invert(1)', padding: '13px', opacity: '85%'}} className="skill-icon" alt="Adobe After Effects" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={premiereImage} style={{ filter: 'invert(1)'}} className="skill-icon" alt="Adobe Premiere Pro" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={resolveImage} style={{ filter: 'invert(1)'}} className="skill-icon" alt="Davinci Resolve" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={canonImage} style={{ filter: 'invert(1)'}} className="skill-icon" alt="Canon EOS" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={djiImage} style={{ filter: 'invert(1)'}} className="skill-icon" alt="DJI" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={rodeImage} style={{ filter: 'invert(1)'}} className="skill-icon" alt="Rode Audio" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={sandiskImage} style={{ filter: 'invert(1)', padding: '0', height: 'auto', width: 'auto' }} className="skill-icon" alt="Sandisk SD Cards" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={ftfImage} style={{ filter: 'invert(1)', padding: '10px'}} className="skill-icon" alt="Full Time Filmmaker Student" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={artlistImage} style={{ filter: 'invert(1)', padding: '0'}} className="skill-icon" alt="Artlist" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={descriptImage} style={{ filter: 'invert(1)', padding: '5px'}} className="skill-icon" alt="Descript" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={megaImage} style={{ filter: 'invert(1)', padding: '10px'}} className="skill-icon" alt="Mega Files" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={epsonImage} style={{ filter: 'invert(1)', padding: '0px'}} className="skill-icon" alt="Epson Printers" />
      </Col>
    </Row>
  );
}

export default Techstack;
