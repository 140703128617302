import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Particle from "../Particle";
import Cal from "../Cal/Cal";
import { AiOutlineMail, AiOutlinePhone, AiFillInstagram } from "react-icons/ai";
import { FaFacebookMessenger } from "react-icons/fa";

function Projects() {

  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: 'center' }}>
          <Col md={7} className="home-header" style={{ justifyContent: 'center', zIndex: '1', marginBottom: '50px', marginTop: '50px' }}>
            
            <h1 className="projects__heading-name">
              <strong className="main-name"> Contact</strong>
            </h1>

            <ul className="home-about-social-links">
                <li className="social-icons">
                <a
                    href="tel:+19154007796"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                >
                    <AiOutlinePhone />
                </a>
                </li>
                <li className="social-icons">
                <a
                    href="mailto:contact@odaproductions.net"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                >
                    <AiOutlineMail />
                </a>
                </li>
            </ul>
            <ul className="home-about-social-links">
                <li className="social-icons">
                <a
                    href="https://m.me/odaproductions915"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                >
                    <FaFacebookMessenger />
                </a>
                </li>
                <li className="social-icons">
                <a
                    href="https://instagram.com/odaproductions"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                >
                    <AiFillInstagram />
                </a>
                </li>
            </ul>


          </Col>

          <Cal />

        </Row>

        <Row style={{ justifyContent: 'center' }}>
            <Col>

            </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
