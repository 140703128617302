import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          // "Unlock Your Brand's Potential",
          // "Your Brand's Full Potential",
          // "Stand Out in the Market",
          // "Turn Views into Sales",

          "Memories Kept Forever",
          "Celebrate!",
          "Congratulations!",
          "Onto the Next Chapter :)"

          // "Such a Beautiful Perfomance!",
          // "The Crowd Loved It!",
          // "Best Dancers in El Paso!"
          
        ],
        autoStart: true,
        loop: true,
        delay: 50,
        deleteSpeed: 40,
      }}
    />
  );
}

export default Type;
