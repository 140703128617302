import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { ImArrowRight2, ImArrowDown2 } from "react-icons/im";

function AboutCard() {
  const [activeSection, setActiveSection] = useState("Section 1"); // Set the initial active section

  const handleSectionClick = (section) => {
    if (section === activeSection) {
      setActiveSection(null); // Close the section if it's already open
    } else {
      setActiveSection(section); // Open the section if it's closed or switch to another section
    }
  };

  const isSectionActive = (section) => section === activeSection;

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "left", wordSpacing: "4px" }}>
            Our agreement stipulates that you, the client, retain full ownership of the final edited videos, photos, and other deliverables. 
            That being said, according to U.S. Copyright Law, ODA Productions retains ownership of the unedited and re-edited versions and is free to use the content for promotion, portfolios, or any other purpose they see fit.
          </p>

          <p style={{ textAlign: "left", wordSpacing: "4px" }}>
            We love connecting with clients on social media! Feel free to send us a DM on Instagram @odaproductions or tag us in a post with the content we delivered!
          </p>
          <ul>
            <strong className="purple" style={{ display: 'block', textAlign: 'left' }}>Common Questions:</strong>

            <li className="about-activity" onClick={() => handleSectionClick("Section 1")} style={{ cursor: "pointer", textDecoration: "underline" }}>
              {isSectionActive("Section 1") ? (
                <ImArrowDown2 /> // Show this icon when the section is active
              ) : (
                <ImArrowRight2 /> // Show this icon when the section is not active
              )} Where can I post the content?
            </li>
            {isSectionActive("Section 1") && (
              <span className="about-description">
                As you retain 100% ownership of the final deliverables, you are allowed to post your content on any platform that suits your needs. Feel free to tag us when you do post! @odaproductions
              </span>
            )}

            <li className="about-activity" onClick={() => handleSectionClick("Section 2")} style={{ cursor: "pointer", textDecoration: "underline"  }}>
              {isSectionActive("Section 2") ? (
                <ImArrowDown2 /> // Show this icon when the section is active
              ) : (
                <ImArrowRight2 /> // Show this icon when the section is not active
              )} Can I edit my photos/videos?
            </li>
            {isSectionActive("Section 2") && (
              <span className="about-description">
                Our team spends lots of time on their craft tweaking and perfecting every photo, video, graphic, etc. and for that reason, we kindly ask that you restrain 
                from editing, applying filters, retouching, etc. as any of these modifications warp the original vision the dedicated artist had in mind.
              </span>
            )}

            {/* <li className="about-activity" onClick={() => handleSectionClick("Section 3")} style={{ cursor: "pointer", textDecoration: "underline"  }}>
              {isSectionActive("Section 3") ? (
                <ImArrowDown2 /> // Show this icon when the section is active
              ) : (
                <ImArrowRight2 /> // Show this icon when the section is not active
              )} 
            </li>
            {isSectionActive("Section 3") && (
              <span className="about-description">
                We appreciate the diversity of cultures and backgrounds and take pride in representing this diversity in our work, creating inclusive and welcoming atmospheres.
              </span>
            )} */}
          </ul>

          {/* <p style={{ color: "#f2ff00 " }}>
            "We craft memories one shot at a time."
          </p>
          <footer className="blockquote-footer">Orlando (Owner)</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
